<template>
  <div>
    <el-card style="min-height: 45rem" v-loading="loading">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="searchForm.level">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="普通" :value="1"></el-option>
              <el-option label="金牌" :value="2"></el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="uid" label="用户编号"></el-table-column>
        <el-table-column prop="nickname" label="用户昵称"></el-table-column>
        <el-table-column prop="phone" label="用户手机号"></el-table-column>
        <el-table-column :formatter="levelFormat" label="等级"></el-table-column>
        <el-table-column label="上级主理人">
          <template #default="s">
            <div class="y-desc">用户编号:{{ s.row.parent_uid }}</div>
            <div class="y-desc">手机号:{{ s.row.parent_u_phone }}</div>
            <div class="y-desc">主理人编号:{{ s.row.parent_id }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="card" label="激活卡剩余数量"></el-table-column>
        <el-table-column label="专属推广码">
          <template #default="s">
            <div>
              <el-button type="text" size="mini" @click="getQrcode(s.row.id,false)">公众号网页码</el-button>
            </div>
            <div>
              <el-button type="text" size="mini" @click="getQrcode(s.row.id,true)">微信小程序码</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="360">
          <template v-slot="s">
            <div class="flex-def">
              <el-button @click="rechargeItem(s.row.uid)">充值激活卡数量</el-button>
              <principal-card-log-by-uid style="margin: 0 1rem" :uid="s.row.uid"></principal-card-log-by-uid>
              <principal-user-detail :uid="s.row.uid"></principal-user-detail>
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog append-to-body title="激活卡数量充值" :visible.sync="rechargeShow">
      <el-form>
        <el-form-item label="充值主理人用户编号">{{ rechargeForm.uid }}</el-form-item>
        <el-form-item label="充值数量">
          <el-input-number v-model="rechargeForm.num"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rechargeShow = false">取 消</el-button>
        <el-button type="primary" @click="rechargeSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import PrincipalCardLogByUid from "@/components/card/PrincipalCardLogByUid.vue";
import PrincipalUserDetail from "@/components/card/PrincipalUserDetail.vue";

const RechargeFormTpl = {
  uid: 0,
  num: 0,
}
export default {
  name: "manage",
  components: {
    PrincipalUserDetail,
    PrincipalCardLogByUid,
  },

  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm: {
        phone: "",
        nickname: "",
        level: 0,
      },
      rechargeForm: {...RechargeFormTpl},
      rechargeShow: false,
      loading: false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    getQrcode(id, is_mini) {
      this.loading = true;
      this.$u.api.card.plugin.principal.pQrcode({id, is_mini}).then(res => {
        window.open(res, "_blank")
      }).finally(() => {
        this.loading = false;
      })
    },
    rechargeItem(uid) {
      this.rechargeForm = {...RechargeFormTpl, uid};
      this.rechargeShow = true;
    },
    rechargeSubmit() {
      this.$u.api.card.plugin.principal.cardRecharge(this.rechargeForm).then(() => {
        this.$message.success("操作成功");
        this.rechargeShow = false;
        this.load();
      })
    },
    levelFormat(s) {
      return s.level == 2 ? "金牌" : "普通"
    },
    searchGo() {
      this.page = 1;
      this.list = [];
      this.load();
    },
    searchReset() {
      this.searchForm = {
        phone: "",
        nickname: "",
        level: 0,
      }
      this.searchGo()
    },
    load() {
      this.$u.api.card.plugin.principal.userSearch({
        page: this.page,
        ...this.searchForm
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>